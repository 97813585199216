<template>
    <div style="height:100%;overflow:hidden;">
        <div class="block" style="height:100%;margin-top:0;">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="privs" label="日期" width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.created_at | moment("YYYY/MM/DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="活动标题">
                        <template slot-scope="scope">
                            <div>{{scope.row.title}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="viewContent(scope.row)" type="text" size="small">
                                查看内容
                            </el-button>
                            <el-button @click="viewSend(scope.row)" type="text" size="small">
                                查看同批次发送
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :total="total"></el-pagination>
            </div>
        </div>

        <el-dialog width="600px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <ViewContent v-if="popTitle=='查看内容'" :form="content" :type="2" @hide="popVisible=false"></ViewContent>
            <ViewSend v-if="popTitle=='查看同批次发送内容'" :form="sendItem" :type="2" @hide="popVisible=false"></ViewSend>
        </el-dialog>
        
    </div>
</template>
<script>
    import _ from 'lodash';
    import { listSendMail,listCompSendMailBatch } from '@/service/crm';
    import ViewSend from '../log/ViewSend.vue';
    import ViewContent from '../log/ViewContent.vue';
    export default {
        components: {
            ViewSend,ViewContent
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    comp_id:1,
                    page:1,
                },
                total:0,
                list:[],
                content:null,
                sendItem:null,
            }
        },
        watch: {
            queryOpt:{
                handler(){
                    this.debounceRefreshItems();
                },
                deep: true
            }
        },
        created() {
            this.queryOpt.comp_id = this.$route.query.id;
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            refreshItems() {
                console.log('refreshItems');
                listSendMail(this.queryOpt).then(rst => {
                    this.total = rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            viewContent(item) {
                this.popTitle = "查看内容";
                this.popVisible = true;
                this.content = item.content;
            },
            async viewSend(item) {
                this.popTitle = "查看同批次发送内容";
                this.popVisible = true;
                this.sendItem = await listCompSendMailBatch({info_id:item.id});
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    
</style>