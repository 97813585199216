import { render, staticRenderFns } from "./MailTemplate.vue?vue&type=template&id=eb5b56ac&scoped=true&"
import script from "./MailTemplate.vue?vue&type=script&lang=js&"
export * from "./MailTemplate.vue?vue&type=script&lang=js&"
import style0 from "../style.css?vue&type=style&index=0&id=eb5b56ac&prod&scoped=true&lang=css&"
import style1 from "./MailTemplate.vue?vue&type=style&index=1&id=eb5b56ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb5b56ac",
  null
  
)

export default component.exports