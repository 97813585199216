<template>
    <div style="height:100%">
        <div class="page-header">邮件模板</div>
        <div class="block tab flex flex-align-center">
            <div class="tab_menu flex-1">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="">
                <el-button @click="goto('/client/editMail')" size="small" type="primary">新增模板</el-button>
            </div>
        </div>
        <div style="height: calc(100% - 180px)">
            <div class="block">
                <div v-if="tabIndex==0" class="list" style="height: calc(100% - 60px);overflow-y: auto">
                    <el-table :data="list" style="width: 100%">
                        <el-table-column prop="id" label="ID" width="120">
                        </el-table-column>
                        <el-table-column prop="name" label="模板名称">
                        </el-table-column>
                        <el-table-column prop="address" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button @click="viewContent(scope.row)" type="text" size="small">
                                    查看内容
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <MailLog v-if="tabIndex==1"></MailLog>
        </div>

        <el-dialog width="600px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <ViewContent v-if="popTitle=='查看内容'" :form="content" :type="2" @hide="popVisible=false"></ViewContent>
        </el-dialog>
    </div>
</template>
<script>
    import { listMailTpl } from '@/service/crm';
    import ViewContent from '../log/ViewContent.vue';
    import MailLog from './MailLog.vue';
    export default {
        components: {
            ViewContent,MailLog
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                tabList:[
                    {name:"邮件模板"},
                    {name:"历史发送记录"},
                ],
                tabIndex:0,
                list:[],
                ruleForm:null,
                content:"",
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                listMailTpl().then(rst => {
                    this.list = rst;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            viewContent(item) {
                this.popTitle = "查看内容";
                this.popVisible = true;
                this.content = item.content;
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* min-width: 460px; */
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 85px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:30px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>